import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
// import Components from './Components';
// import Apps from './Apps';
// import Extensions from './Extensions';
// import Charts from './Charts';
// import Maps from './Maps';
// import Widgets from './Widgets';
// import Metrics from './Metrics';
import Login from './Auth/Login';
// import Signup from './Auth/Register';
// import ForgotPassword from './Auth/ForgotPassword';
// import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import ExtraPages from './ExtraPages';
// import TourGuide from './TourGuide';
import SuperLogin from './Auth/SuperLogin';
import Versions from './Versions';
import Members from './Members';
import Customers from './Customers';
import Suburb from './Suburb';
import Services from './Services';
import Forum from './Forum';
import Inventory from './Inventory';
import Freight from './Freight';
import Financial from './Financial';
import PaymentType from './PaymentType';
import Documents from './Documents';
import News from './News';
import Orders from './Orders';
import Leads from './Leads';
import SocialMedia from './SocialMedia';
import NotFound from './ExtraPages/404';
import Reports from './Reports';
import Bookings from './Bookings';
import AutoReply from './AutoReply';
import LeadSuspendReason from './LeadSuspendReason';
import Uniform from './Uniform';
import Flyers from './Flyers';
import Security from './Security';

// import LayoutBuilder from './LayoutBuilder';
const loginToken = JSON.parse(localStorage.getItem('token'));
const incomeReport = JSON.parse(localStorage.getItem('income_report_filter'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        loginToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/404',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  // const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const url = window.location.hostname;
  const splittedUrl = url.split('.');

  let domainName = '';
  if (splittedUrl.length >= 3) {
    domainName = splittedUrl[0];
  }

  if ((location.pathname === '' || location.pathname === '/') && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/dashboard' && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/admin' && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/super' && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/admin/' && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/super/' && loginToken) {
    return <Redirect to={'/dashboard/dashboard'} />;
  } else if (location.pathname === '/php/api/app/1.0/income/user-export' && loginToken) {
    return (
      <Redirect
        to={`/php/api/app/1.0/income/user-export?startDate=${incomeReport.currStartDate}&endDate=${incomeReport.currEndDate}`}
      />
    );
  } else if (location.pathname === '/super/' && !loginToken) {
    return <Redirect to={'/super'} />;
  } else if (location.pathname === '/admin/' && !loginToken) {
    return <Redirect to={'/admin'} />;
  } else if (location.pathname === '/security/TwoFA' && domainName === 'backadmin' && !loginToken) {
    return <Redirect to={'/super'} />;
  } else if (location.pathname === '/security/TwoFA' && domainName === 'admin' && !loginToken) {
    return <Redirect to={'/admin'} />;
  } else if ((location.pathname === '' || location.pathname === '/') && !loginToken) {
    return <Redirect to={'/super'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        {/* <Route path="/dashboard" component={Dashboards} /> */}
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/versions" component={Versions} />
        <RestrictedRoute path="/members" component={Members} />
        <RestrictedRoute path="/customers" component={Customers} />
        <RestrictedRoute path="/suburb" component={Suburb} />
        <RestrictedRoute path="/services" component={Services} />
        <RestrictedRoute path="/forum" component={Forum} />
        <RestrictedRoute path="/inventory" component={Inventory} />
        <RestrictedRoute path="/uniform" component={Uniform} />
        <RestrictedRoute path="/flyers" component={Flyers} />
        <RestrictedRoute path="/freight" component={Freight} />
        <RestrictedRoute path="/financial" component={Financial} />
        <RestrictedRoute path="/paymentType" component={PaymentType} />
        <RestrictedRoute path="/documents" component={Documents} />
        <RestrictedRoute path="/news" component={News} />
        <RestrictedRoute path="/orders" component={Orders} />
        <RestrictedRoute path="/leads" component={Leads} />
        <RestrictedRoute path="/leadSuspendReason" component={LeadSuspendReason} />
        <RestrictedRoute path="/emailTemplate" component={AutoReply} />
        <RestrictedRoute path="/social" component={SocialMedia} />
        <RestrictedRoute path="/reports" component={Reports} />
        <RestrictedRoute path="/bookings" component={Bookings} />
        <RestrictedRoute path="/security" component={Security} />

        {/* <Route path="/widgets" component={Widgets} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/components" component={Components} />
        <Route path="/extensions" component={Extensions} />
        <Route path="/visualization/chart" component={Charts} />
        <Route path="/visualization/map" component={Maps} />
        <Route path="/extra-pages" component={ExtraPages} /> */}
        {/* <Route path="/apps" component={Apps} /> */}

        <Route path="/404" component={NotFound} />
        <Route path="/admin" component={Login} />
        <Route path="/super" component={SuperLogin} />
        <RestrictedRoute path="*" component={NotFound} />

        {/* <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} /> */}
        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>

      {/* {location.pathname !== '/admin' &&
        location.pathname !== '/super' &&
        location.pathname !== '/signup' &&
        location.pathname !== '/forgot-password' && 
        <TourGuide />} */}
    </React.Fragment>
  );
};

export default Routes;
