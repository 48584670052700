import React, { useEffect, useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import { Close, FilterList } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import { useLocation } from 'react-router';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  const location = useLocation();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [members, setMembers] = useState([]);
  const [isSystem, setIsSystem] = useState(false);
  var memberName;

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getAllMembers();
    console.log('lcoation', location.pathname);
    if (location.pathname === '/social/listSystemGroups') {
      setIsSystem(true);
    } else {
      setIsSystem(false);
    }
  }, []);

  const getAllMembers = () => {
    httpClient.get('member/all').then(({ data }) => {
      if (data.success) {
        setMembers(data.data);
      }
    });
  };

  members &&
    members.map(item => {
      if (item.memberID == props.filterData.addedBy) {
        memberName = item.firstName + ' ' + item.lastName;
      } else if (props.filterData.addedBy == '0') {
        memberName = 'Super Admin';
      }
    });

  // const sendTo = () => {
  //   history.push('/social/addSocialGroup');
  // };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Social Group Name </InputLabel>
                  <TextField
                    variant="outlined"
                    name="socialGroupName"
                    value={props.filterData.socialGroupName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Added By</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="addedBy"
                      value={props.filterData.addedBy}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {isSystem ? (
                        <MenuItem value={'0'}>Super Admin</MenuItem>
                      ) : (
                        members &&
                        members.map(item => {
                          return (
                            <MenuItem value={item.memberID} key={item.memberID}>
                              {item.firstName + ' ' + item.lastName}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Social Group Public</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="isSocialGroupPublic"
                      // label="Migrated Members"
                      value={props.filterData.isSocialGroupPublic}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value={'1'}>Yes</MenuItem>
                      <MenuItem value={'0'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.socialGroupName ||
        props.submittedData.addedBy ||
        props.submittedData.isSocialGroupPublic ||
        props.submittedData.active ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.active && (
              <p>
                Active Members: <span>{props.submittedData.active == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )}
            {props.submittedData.isSocialGroupPublic && (
              <p>
                Public Group:<span> {props.submittedData.isSocialGroupPublic == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('isSocialGroupPublic')} />
              </p>
            )}

            {props.submittedData.addedBy && (
              <p>
                Added By: <span>{memberName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('addedBy')} />
              </p>
            )}

            {props.submittedData.socialGroupName && (
              <p>
                Social Group Name: <span>{props.submittedData.socialGroupName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('socialGroupName')} />
              </p>
            )}

            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          viewPosts={props.viewPosts}
          viewAllMembers={props.viewAllMembers}
          handleDelete={props.handleDelete}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          loading={props.loading}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
