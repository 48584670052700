import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import MemberDialog from '../MemberDialog';
import PostDialog from '../PostDialog';
import TableSocialComponent from '../TableSocialComponent';

const columns = [
  { id: 'socialGroupName', label: 'Social Group Name' },
  { id: 'socialGroupDescription', label: 'Description' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'isSocialGroupPublic', label: 'Public/Private' },
  { id: 'socialGroupAddedDate', label: 'Added Date' },
  { id: 'socialGroupActive', label: 'Active' },
  { id: 'Actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListSocialMediaGroup = () => {
  const [system, setSystem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [showMembersDialog, setShowMembersDialog] = useState(false);
  const [showPostDialog, setShowPostDialog] = useState(false);
  const [rowDetails, setRowDetails] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    socialGroupName: '',
    isSocialGroupPublic: '',
    addedBy: '',
    active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    socialGroupName: '',
    isSocialGroupPublic: '',
    addedBy: '',
    active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.socialGroupName === '' &&
      filterData.isSocialGroupPublic === '' &&
      filterData.addedBy === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.socialGroupName === ' ') filterData.socialGroupName = '';
    if (filterData.isSocialGroupPublic === ' ') filterData.isSocialGroupPublic = '';
    if (filterData.addedBy === ' ') filterData.addedBy = '';
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let social_group = JSON.parse(localStorage.getItem('social_group'));
    social_group !== null && setFilterData(social_group);

    social_group == null
      ? getSocial()
      : social_group.socialGroupName == '' &&
        social_group.isSocialGroupPublic == '' &&
        social_group.addedBy == '' &&
        social_group.active == '' &&
        social_group.search == '' &&
        social_group.removed == false
      ? getSocial()
      : console.log('social_group');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      socialGroupName: filterData.socialGroupName,
      isSocialGroupPublic: filterData.isSocialGroupPublic,
      addedBy: filterData.addedBy,
      active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('social_group', JSON.stringify(filterData));
    if (
      filterData.socialGroupName ||
      filterData.isSocialGroupPublic ||
      filterData.addedBy ||
      filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `social-group/filter?q=${filterData.search}&socialGroupName=${filterData.socialGroupName}&isSocialGroupPublic=${filterData.isSocialGroupPublic}&createdByUserID=${filterData.addedBy}&socialGroupActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSystem(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setRowsPerPage(data.meta.per_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getSocial();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  // useEffect(() => {
  //   getSocial();
  // }, []);

  const getSocial = () => {
    setLoading(true);
    httpClient.get(`social-group?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSystem(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`social-group?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSystem(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `social-group/filter?q=${filterData.search}&socialGroupName=${filterData.socialGroupName}&isSocialGroupPublic=${filterData.isSocialGroupPublic}&createdByUserID=${filterData.addedBy}&socialGroupActive=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSystem(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`social-group?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSystem(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `social-group/filter?q=${filterData.search}&socialGroupName=${filterData.socialGroupName}&isSocialGroupPublic=${
              filterData.isSocialGroupPublic
            }&createdByUserID=${filterData.addedBy}&socialGroupActive=${filterData.active}&pagination=${+event.target
              .value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSystem(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`social-group?sort_by=companyID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSystem(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleEdit = id => {
    history.push(`/social/addSocialGroup/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getSocial();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`social-group/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setSystem(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };
  const viewAllMembers = data => {
    // console.log('view all members',data);
    setShowMembersDialog(true);
    setRowDetails(data.memID);
  };

  const sendBackMember = call => {
    if (call.open === false) {
      setShowMembersDialog(false);
      setRowDetails('');
    }
  };

  const viewPosts = data => {
    setShowPostDialog(true);
    setRowDetails(data.memID);
  };

  const sendBackPost = callBack => {
    if (callBack.open === false) {
      setShowPostDialog(false);
      setRowDetails('');
    }
  };

  return (
    <PageContainer heading="List Social Groups">
      <TableSocialComponent
        columns={columns}
        selectedProducts={system}
        title={'Social Groups'}
        viewAllMembers={viewAllMembers}
        viewPosts={viewPosts}
        // handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        direction={direction}
        currentColumn={currentColumn}
      />

      {showMembersDialog && <MemberDialog rowDetails={rowDetails} sendBackMember={sendBackMember} />}

      {showPostDialog && <PostDialog rowDetails={rowDetails} sendBackPost={sendBackPost} />}
    </PageContainer>
  );
};

export default ListSocialMediaGroup;
