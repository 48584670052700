import React, { useEffect, useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import { Close, FilterList } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import { useLocation } from 'react-router';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  const location = useLocation();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [members, setMembers] = useState([]);
  const [socialGroups, setSocialGroups] = useState([]);
  var memberName, groupName;

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getAllMembers();
    getAllGroups();
  }, []);

  const getAllMembers = () => {
    httpClient.get('member/all').then(({ data }) => {
      if (data.success) {
        setMembers(data.data);
      }
    });
  };

  const getAllGroups = () => {
    httpClient.get('social-group/all').then(({ data }) => {
      if (data.success) {
        setSocialGroups(data.data);
      }
    });
  };

  // members &&
  //   members.map(item => {
  //     if (item.memberID == props.filterData.addedBy) {
  //       memberName = item.firstName + ' ' + item.lastName;
  //     } else if (props.filterData.addedBy == '0') {
  //       memberName = 'Super Admin';
  //     }
  //   });

  //   socialGroups &&
  //   socialGroups.map(item => {
  //     if (item.socialGroupID == props.filterData.postedBy) {
  //       memberName = item.socialGroupName;
  //     }
  //   });

  // const sendTo = () => {
  //   history.push('/social/addSocialGroup');
  // };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  console.log('props', props.filterData);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Post Title </InputLabel>
                  <TextField
                    variant="outlined"
                    name="title"
                    value={props.filterData.title}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Added By</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="postedBy"
                      value={props.filterData.postedBy}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">&nbsp;</MenuItem>

                      {members &&
                        members.map(item => {
                          return (
                            <MenuItem value={item.firstName + ' ' + item.lastName} key={item.memberID}>
                              {item.firstName + ' ' + item.lastName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Social Group</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="groupName"
                      value={props.filterData.groupName}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">&nbsp;</MenuItem>

                      {socialGroups &&
                        socialGroups.map(item => {
                          return (
                            <MenuItem value={item.socialGroupName} key={item.socialGroupName}>
                              {item.socialGroupName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.title ||
        props.submittedData.postedBy ||
        // props.submittedData.isSocialGroupPublic ||
        props.submittedData.groupName ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {/* {props.submittedData.active && (
              <p>
                Active Members: <span>{props.submittedData.active == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )} */}
            {props.submittedData.groupName && (
              <p>
                Group Name:<span> {props.submittedData.groupName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('groupName')} />
              </p>
            )}

            {props.submittedData.postedBy && (
              <p>
                Posted By: <span>{props.submittedData.postedBy} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('postedBy')} />
              </p>
            )}

            {props.submittedData.title && (
              <p>
                Post Title: <span>{props.submittedData.title} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('title')} />
              </p>
            )}

            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          viewDetails={props.viewDetails}
          handleDelete={props.handleDelete}
          handleActivate={props.handleActivate}
          handleDeactivate={props.handleDeactivate}
          handleSort={props.handleSort}
          loading={props.loading}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
