import React, { useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import TableComponent from './../TableComponent';
import { httpClient } from 'appUtility/Api';
import { history } from 'redux/store';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertDialog from './../AlertDialog';
import ResetPassword from '../ResetPassword';
import TopUpCredit from '../TopUpCredit';
import EnableDisableIPad from '../EnableDisableIPad';
import ForumMgmt from '../ForumMgmt';
import InventoryUsageDialog from '../InventoryUsageDialog';
import StockOnHandDialog from '../StockOnHandDialog';

const qs = require('qs');

const columns = [
  { id: 'firstName', label: 'Name' },
  { id: 'memberID', label: 'Company Name' },
  { id: 'lastName', label: 'Service Location' },
  { id: 'appLevelAdminID', label: 'Type' },
  { id: 'lastActive', label: 'Last Active' },
  { id: 'iPadActive', label: 'iPAD' },
  { id: 'memberActive', label: 'Member Active' },
  { id: 'TsandCs', label: 'Ts & Cs Accepted' },
  { id: 'actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
// const memberFilterData = JSON.parse(localStorage.getItem("member_filter"));

const ListMembers = props => {
  let login = localStorage.getItem('login');
  let loginData = JSON.parse(login);
  const [members, setMembers] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [inActiveMembers, setInActiveMembers] = useState([]);
  const [socialActiveMembers, setSocialActiveMembers] = useState([]);
  const [migratedMembers, setMigratedMembers] = useState([]);
  const [TsandCs, setTsandCsMembers] = useState([]);
  const [loginToAdminMembers, setloginToAdminMembers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  // const [direction, setDirection] = useState('asc' || 'desc');
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openTopupDialog, setOpenTopupDialog] = useState(false);
  const [openIpadDialog, setOpenIpadDialog] = useState(false);
  const [openForumDialog, setOpenForumDialog] = useState(false);
  const [openInventoryUsageDialog, setOpenInventoryUsageDialog] = useState(false);
  const [openStockOnHandDialog, setOpenStockOnHandDialog] = useState(false);
  const [forumID, setForumID] = useState('');
  const [ipadID, setIpadID] = useState('');
  const [topID, setTopID] = useState('');
  const [passwordID, setPasswordID] = useState('');
  const [deleteID, setDeleteID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [memberID, setMemberID] = useState('');
  const [companyID, setCompanyID] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    // serviceLocations: '',
    active: '',
    social: '',
    suspendLead: '',
    migrated: '',
    adminMembers: '',
    state: '',
    memberType: '',
    ipad: '',
    bookingNumber: '',
    stockTake: '',
    enableTwoFactor: '',
    search: '',
    TsandCs: '',
  });
  const [filterData, setFilterData] = useState({
    // serviceLocations: '',
    active: '',
    social: '',
    suspendLead: '',
    migrated: '',
    adminMembers: '',
    state: '',
    memberType: '',
    ipad: '',
    bookingNumber: '',
    stockTake: '',
    enableTwoFactor: '',
    search: '',
    TsandCs: '', // terms and condition accepted (1-yes, 0-no)
    removed: false,
  });

  useEffect(() => {
    let memberStorage = JSON.parse(localStorage.getItem('member_filter'));
    memberStorage !== null && setFilterData(memberStorage);

    memberStorage == null
      ? getAllMembers()
      : memberStorage.active == '' &&
        memberStorage.bookingNumber == '' &&
        memberStorage.social == '' &&
        memberStorage.suspendLead == '' &&
        memberStorage.migrated == '' &&
        memberStorage.adminMembers == '' &&
        memberStorage.state == '' &&
        memberStorage.memberType == '' &&
        memberStorage.ipad == '' &&
        memberStorage.stockTake == '' &&
        memberStorage.enableTwoFactor == '' &&
        memberStorage.search == '' &&
        memberStorage.TsandCs === '' &&
        memberStorage.removed == false
      ? getAllMembers()
      : console.log('data');

    props.location.query &&
      props.location.query.member_type === 'active' &&
      setFilterData({
        active: '1',
        social: '',
        suspendLead: '',
        migrated: '',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '1',
      suspendLead: '',
      social: '',
      migrated: '',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      bookingNumber: '',
      stockTake: '',
      enableTwoFactor: '',
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'inactive' &&
      setFilterData({
        active: '0',
        social: '',
        suspendLead: '',
        migrated: '',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '0',
      social: '',
      suspendLead: '',
      migrated: '',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      bookingNumber: '',
      stockTake: '',
      enableTwoFactor: '',
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'socialactive' &&
      setFilterData({
        active: '',
        social: '1',
        suspendLead: '',
        migrated: '',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        removed: true,
        TsandCs: '',
      });
    setSubmittedData({
      submit: true,
      active: '',
      social: '1',
      suspendLead: '',
      migrated: '',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      bookingNumber: '',
      stockTake: '',
      enableTwoFactor: '',
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'migrated' &&
      setFilterData({
        active: '',
        social: '',
        suspendLead: '',
        migrated: '1',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '',
      social: '',
      suspendLead: '',
      migrated: '1',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      bookingNumber: '',
      stockTake: '',
      enableTwoFactor: '',
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'members-with-admin-access' &&
      setFilterData({
        active: '',
        social: '',
        migrated: '',
        suspendLead: '',
        adminMembers: '1',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '',
      suspendLead: '',
      social: '',
      migrated: '',
      adminMembers: '1',
      state: '',
      memberType: '',
      ipad: '',
      bookingNumber: '',
      stockTake: '',
      enableTwoFactor: '',
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'pending_booking' &&
      setFilterData({
        active: '',
        social: '',
        suspendLead: '',
        migrated: '',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: 10,
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '',
      suspendLead: '',
      social: '',
      migrated: '',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      stockTake: '',
      enableTwoFactor: '',
      bookingNumber: 10,
      search: '',
      TsandCs: '',
    });

    props.location.query &&
      props.location.query.member_type === 'tc-accepted-members' &&
      setFilterData({
        active: '',
        social: '',
        suspendLead: '',
        migrated: '',
        adminMembers: '',
        state: '',
        memberType: '',
        ipad: '',
        bookingNumber: '',
        stockTake: '',
        enableTwoFactor: '',
        search: '',
        TsandCs: '0',
        removed: true,
      });
    setSubmittedData({
      submit: true,
      active: '',
      suspendLead: '',
      social: '',
      migrated: '',
      adminMembers: '',
      state: '',
      memberType: '',
      ipad: '',
      stockTake: '',
      enableTwoFactor: '',
      bookingNumber: '',
      search: '',
      TsandCs: '0',
    });

    // props.location.query == undefined &&
    //   setFilterData({
    //     active: '',
    //     social: '',
    //     migrated: '',
    //     adminMembers: '',
    //     state: '',
    //     memberType: '',
    //     ipad: '',
    //     bookingNumber: '',
    //     search: '',
    //     removed: true,
    //   });
    // setSubmittedData({
    //   submit: true,
    //   active: '',
    //   social: '',
    //   migrated: '',
    //   adminMembers: '',
    //   state: '',
    //   memberType: '',
    //   ipad: '',
    //   bookingNumber: '',
    //   search: '',
    // });
  }, []);

  useEffect(() => {
    if (
      // filterData.serviceLocations === '' &&
      filterData.active === '' &&
      filterData.bookingNumber === '' &&
      filterData.social === '' &&
      filterData.suspendLead === '' &&
      filterData.migrated === '' &&
      filterData.adminMembers === '' &&
      filterData.state === '' &&
      filterData.ipad === '' &&
      filterData.memberType === '' &&
      filterData.stockTake === '' &&
      filterData.enableTwoFactor === '' &&
      // filterData.credits === '' &&
      filterData.search === '' &&
      filterData.TsandCs === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.bookingNumber === ' ') filterData.bookingNumber = '';
    if (filterData.social === ' ') filterData.social = '';
    if (filterData.suspendLead === ' ') filterData.suspendLead = '';
    if (filterData.migrated === ' ') filterData.migrated = '';
    if (filterData.adminMembers === ' ') filterData.adminMembers = '';
    if (filterData.state === ' ') filterData.state = '';
    if (filterData.ipad === ' ') filterData.ipad = '';
    if (filterData.memberType === ' ') filterData.memberType = '';
    if (filterData.stockTake === ' ') filterData.stockTake = '';
    if (filterData.enableTwoFactor === ' ') filterData.enableTwoFactor = '';
    // if (filterData.credits === ' ') filterData.credits = '';
    if (filterData.search === ' ') filterData.search = '';
    if (filterData.TsandCs === ' ') filterData.TsandCs = '';

    filterData.removed === true && handleSubmitData();

    console.log('filter data is>>>', filterData);
  }, [filterData]);

  const handleSubmitData = () => {
    setSubmittedData({
      // serviceLocations: filterData.serviceLocations ,
      active: filterData.active,
      social: filterData.social,
      suspendLead: filterData.suspendLead,
      migrated: filterData.migrated,
      adminMembers: filterData.adminMembers,
      state: filterData.state,
      memberType: filterData.memberType,
      ipad: filterData.ipad,
      stockTake: filterData.stockTake,
      enableTwoFactor: filterData.enableTwoFactor,
      // credits: filterData.credits,
      search: filterData.search,
      bookingNumber: filterData.bookingNumber,
      TsandCs: filterData.TsandCs,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);

    localStorage.setItem('member_filter', JSON.stringify(filterData));
    if (
      filterData.active ||
      filterData.bookingNumber ||
      filterData.social ||
      filterData.suspendLead ||
      filterData.migrated ||
      filterData.adminMembers ||
      filterData.state ||
      filterData.memberType ||
      filterData.ipad ||
      filterData.stockTake ||
      filterData.enableTwoFactor ||
      filterData.search ||
      filterData.TsandCs

      // filterData.credits
    ) {
      httpClient
        .get(
          `member/filter?q=${filterData.search}&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${filterData.active}&suspendLead=${filterData.suspendLead}&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${filterData.adminMembers}&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${filterData.ipad}&stockTake=${filterData.stockTake}&pagination=${rowsPerPage}&enableTwoFactor=${filterData.enableTwoFactor}&TsandCs=${filterData.TsandCs}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setMembers(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllMembers();
    }
  };

  const handleFilter = e => {
    // e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getAllMembers = () => {
    setLoading(true);
    // props.location.query && props.location.query.member_type
    //   ? httpClient
    //       .get(`member/type/${props.location.query && props.location.query.member_type}?&pagination=${rowsPerPage}`)
    //       .then(({ data }) => {
    //         if (data) {
    //           setMembers(data.data);
    //           setTotal(data.meta.total);
    //           // setRowsPerPage(data.meta.per_page);
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         } else {
    //           setLoading(false);
    //         }
    //       })
    //   :
    httpClient.get(`member?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data) {
        setMembers(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpClient
          .get(
            `member/filter?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&q=${
              filterData.search
            }&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${filterData.active}&suspendLead=${
              filterData.suspendLead
            }&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${
              filterData.adminMembers
            }&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${
              filterData.ipad
            }&stockTake=${filterData.stockTake}&pagination=${rowsPerPage}&page=${page}&enableTwoFactor=${
              filterData.enableTwoFactor
            }&TsandCs=${filterData.TsandCs}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setMembers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
            }
          })
      : httpClient
          .get(`member/filter?sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
          .then(({ data }) => {
            if (data.success === true) {
              setMembers(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `member/filter?sort_by=${currentColumn.id ? currentColumn.id : ''}&direction=${direction ? 'asc' : 'desc'}&q=${
              filterData.search
            }&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${filterData.active}&suspendLead=${
              filterData.suspendLead
            }&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${
              filterData.adminMembers
            }&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${
              filterData.ipad
            }&stockTake=${filterData.stockTake}&pagination=${rowsPerPage}&page=${page}&enableTwoFactor=${
              filterData.enableTwoFactor
            }&TsandCs=${filterData.TsandCs}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setMembers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `member?sort_by=${currentColumn.id ? currentColumn.id : ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setMembers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `member/filter?q=${filterData.search}&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${
              filterData.active
            }&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${
              filterData.adminMembers
            }&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${
              filterData.ipad
            }&stockTake=${filterData.stockTake}&sort_by=${currentColumn.id}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${+event.target.value}&page=${1}&enableTwoFactor=${filterData.enableTwoFactor}&TsandCs=${
              filterData.TsandCs
            }`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setMembers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`member?sort_by=memberID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setMembers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleEdit = val => {
    // console.log('row', val)
    history.push(`/members/addMembers/${val.memID.memberID}`);
  };

  const handleResetPassword = val => {
    // history.push(`/members/resetPassword/${val.memID.memberID}`);
    setOpenResetDialog(true);
    setPasswordID(val.memID.memberID);
  };

  const sendResponse = callback => {
    // console.log('callback is', callback);
    if (callback.open === true) {
      setOpen(callback.open);
      setMessage(callback.message);
      setMessageState(callback.messageState);
    }
  };

  const sendData = call => {
    // console.log('reback>>>>', call);
    // if(call.submit === true){
    call.open === false && setOpenResetDialog(false);
    // call.open === true && setOpenResetDialog(true);
    // }
  };

  const handleDelete = val => {
    setOpenDialog(true);
    setDeleteID(val.memID.memberID);
    setActiveStatus('Deactivate');
  };
  const handleActivate = val => {
    setOpenDialog(true);
    setDeleteID(val.memID.memberID);
    setActiveStatus('Activate');
  };

  const sendDeactivate = callback => {
    callback.open == false && setOpenDialog(false);
    if (callback.success === true) {
      httpClient.get(`member/change-status/${deleteID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          setTimeout(() => {
            if (
              filterData.active == '' &&
              filterData.bookingNumber == '' &&
              filterData.social == '' &&
              filterData.migrated == '' &&
              filterData.adminMembers == '' &&
              filterData.state == '' &&
              filterData.memberType == '' &&
              filterData.ipad == '' &&
              filterData.stockTake == '' &&
              filterData.enableTwoFactor == '' &&
              filterData.search == ''
            ) {
              // getAllMembers();
              window.location.reload();
            } else {
              handleSubmitData();
            }
          }, 1000);
        } else {
          setOpen(true);
          setMessage(data.message);
          setMessageState('error');
        }
      });
    }
  };

  const handleEnableDisableIpad = val => {
    // history.push(`/members/enableDisableIPad/${val.memID.memberID}`);
    setOpenIpadDialog(true);
    setIpadID(val.memID.memberID);
  };

  const sendIpadData = child => {
    // console.log('ipad child', child);
    child.open === false && setOpenIpadDialog(false);
  };

  const handleForumMgmt = val => {
    // history.push(`/members/forumMgmt/${val.memID.memberID}`);
    setOpenForumDialog(true);
    setForumID(val.memID.memberID);
  };

  const handleInventoryUsage = val => {
    setOpenInventoryUsageDialog(true);
    setMemberID(val?.memID?.memberID);
    setCompanyID(val?.memID?.companyID?.companyID);
  };

  const handleStockTake = val => {
    history.push(`/members/viewStockTake/${val.memID.memberID}`);
  };

  const handleStockOnHand = val => {
    setOpenStockOnHandDialog(true);
    setMemberID(val?.memID?.memberID);
  };

  const sendForumData = child => {
    child.open === false && setOpenForumDialog(false);
  };

  const handleMonetization = val => {
    // history.push(`/members/topUpCredit/${val.memID.memberID}`);
    setOpenTopupDialog(true);
    setTopID(val.memID.memberID);
  };
  const handleLoginTBA = val => {
    var url = `https://tfg.franchise.care/php/admin/Login_As.php?do=login&user=${val.memID.companyID.contactPersonEmailAddress}&appid=1&memid=${val.memID.memberID}&compid=${val.memID.companyID.companyID}`;
    setTimeout(() => {
      // console.log('ulr', url);
      window.open(url, '_blank').focus();
    }, 500);
  };

  const sendTopUp = child => {
    // console.log('top up >>', child);
    child.open === false && setOpenTopupDialog(false);
  };

  const handleCustomers = () => {};

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllMembers();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`member/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setMembers(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const handleSocial = val => {
    httpClient.post(`member/social-access/${val.memID.memberID}`).then(({ data }) => {
      if (data.success === true) {
        setOpen(true);
        setMessage(data.message);
        setMessageState('success');
        if (
          filterData.active == '' &&
          filterData.bookingNumber == '' &&
          filterData.social == '' &&
          filterData.migrated == '' &&
          filterData.adminMembers == '' &&
          filterData.state == '' &&
          filterData.memberType == '' &&
          filterData.ipad == '' &&
          filterData.stockTake == '' &&
          filterData.enableTwoFactor == '' &&
          filterData.search == '' &&
          filterData.TsandCs === ''
        ) {
          // getAllMembers();
          window.location.reload();
        } else {
          handleSubmitData();
        }
      } else {
        setOpen(true);
        setMessage(data.message);
        setMessageState('error');
      }
    });
  };

  const handleBuySell = val => {
    history.push(`/members/buySellProcess/${val.memID.memberID}`);
  };

  // console.log('submitted data >>', submittedData);

  return (
    <PageContainer heading="Members">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={members}
              title={'List Members'}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleActivate={handleActivate}
              handleResetPassword={handleResetPassword}
              handleEnableDisableIpad={handleEnableDisableIpad}
              handleSocialInactive={handleSocial}
              handleSocialActive={handleSocial}
              handleForumMgmt={handleForumMgmt}
              handleInventoryUsage={handleInventoryUsage}
              handleStockTake={handleStockTake}
              handleStockOnHand={handleStockOnHand}
              handleMonetization={handleMonetization}
              handleLoginTBA={handleLoginTBA}
              handleBuySell={handleBuySell}
              handleCustomers={handleCustomers}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {openDialog && <AlertDialog sendDeactivate={sendDeactivate} activeStatus={activeStatus} />}

      {openResetDialog && <ResetPassword passwordID={passwordID} sendResponse={sendResponse} sendData={sendData} />}

      {openTopupDialog && <TopUpCredit topID={topID} sendTopUp={sendTopUp} sendResponse={sendResponse} />}

      {openIpadDialog && <EnableDisableIPad ipadID={ipadID} sendResponse={sendResponse} sendIpadData={sendIpadData} />}

      {openForumDialog && <ForumMgmt forumID={forumID} sendResponse={sendResponse} sendForumData={sendForumData} />}

      {openInventoryUsageDialog && (
        <InventoryUsageDialog
          memberID={memberID}
          companyID={companyID}
          open={openInventoryUsageDialog}
          setOpen={setOpenInventoryUsageDialog}
        />
      )}

      {openStockOnHandDialog && (
        <StockOnHandDialog memberID={memberID} open={openStockOnHandDialog} setOpen={setOpenStockOnHandDialog} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListMembers;
