import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  FormGroup,
  IconButton,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { history } from 'redux/store';
import './custom.css';
import { Cancel, Clear } from '@material-ui/icons';
const qs = require('qs');

const useStyles = makeStyles({
  text: {
    width: '100%',
    margin: '10px',
  },
  root: {
    padding: '40px',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddSocialGroup = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [socialID, setSocialID] = useState(props && props.location.pathname.slice(23));
  const [members, setMembers] = useState([]);
  const [state, setState] = useState([]);

  const [socialDetails, setSocialDetails] = useState({
    socialGroupName: '',
    socialGroupDescription: '',
    createdByUserID: 0,
    isSocialGroupPublic: false,
    socialGroupActive: false,
    memberToAssociate: '',
  });

  var [socialMembers, setSocialMembers] = useState([]);
  var [allMember, setAllMember] = useState(false);
  const [search, setSearch] = useState('');
  var [filteredData, setFilteredData] = useState([]);
  // var socialMembers = new Map();

  useEffect(() => {
    getSocialGroup();
    getAllMember();
    getState();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getAllMember = () => {
    httpClient.get('member/all').then(({ data }) => {
      if (data.success === true) {
        let newData = [];
        data.data.map(member => {
          if(member.memberID !== 210){
            newData.push(member);
          }
        })
        setMembers(newData);
        setFilteredData(newData);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSocialDetails({
      ...socialDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setSocialDetails({
      ...socialDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    socialID === ''
      ? httpClient
          .post(
            'social-group',
            qs.stringify({
              socialGroupName: socialDetails.socialGroupName,
              socialGroupDescription: socialDetails.socialGroupDescription,
              createdByUserID: socialDetails.createdByUserID,
              isSocialGroupPublic: socialDetails.isSocialGroupPublic === true ? 1 : 0,
              socialGroupActive: socialDetails.socialGroupActive === true ? 1 : 0,
              memberToAssociate: socialDetails.memberToAssociate,
              memberID: socialMembers
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSocialDetails({
                socialGroupName: '',
                socialGroupDescription: '',
                createdByUserID: '',
                isSocialGroupPublic: false,
                socialGroupActive: false,
                memberToAssociate: '',
              });
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
            } else {
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `social-group/${socialID}`,
            qs.stringify({
              socialGroupName: socialDetails.socialGroupName,
              socialGroupDescription: socialDetails.socialGroupDescription,
              createdByUserID: socialDetails.createdByUserID,
              isSocialGroupPublic: socialDetails.isSocialGroupPublic === true ? 1 : 0,
              socialGroupActive: socialDetails.socialGroupActive === true ? 1 : 0,
              memberToAssociate: socialDetails.memberToAssociate,
              memberID: socialMembers
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                data.data.isSocialGroupPublic === "Public" ? 
                history.push('/social/listUserGroups') :
                history.push('/social/listSystemGroups');
              }, 1500);
            } else {
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
            }
          });
  };

  const getSocialGroup = () => {
    socialID &&
      httpClient.get(`/social-group/${socialID}`).then(({ data }) => {
        if (data.success === true) {
          setSocialDetails({
            socialGroupName: data.data.socialGroupName,
            socialGroupDescription: data.data.socialGroupDescription,
            createdByUserID: data.data.createdBy.memberID || data.data.createdBy.userID,
            isSocialGroupPublic: data.data.isSocialGroupPublic === 'Public' ? true : false,
            socialGroupActive: data.data.socialGroupActive === 'Active' ? true : false,
            memberToAssociate: data.data.memberToAssociate,
          });
          if(data.data?.socialUser){
            var newSocialIDs = [];
            data.data.socialUser.map(social => {
              newSocialIDs.push(social.userID);
            })
            setSocialMembers(newSocialIDs);
          }
        }
      });
  };

  const handleClickMember = (e, id) => {
    const { checked } = e.target;

    if (checked === true) {
      setSocialMembers([...socialMembers, id]);
    }
    if (checked === false) {
      var newResult = socialMembers.filter(soc => soc !== id);
      setSocialMembers(newResult);
    }
  };

  const handleRemoveMember = (id) => {
    var newResult = socialMembers.filter(soc => soc !== id);
    setSocialMembers(newResult);
  }

  const handleCheckAll = e => {
    const { checked } = e.target;

    setAllMember(checked);

    if (checked === true) {
      members.map(mem => {
        socialMembers.push(mem.memberID);
      });
    }
    if (checked === false) {
      setSocialMembers([]);
    }
  };

  const handleSearchChange = e => {
    const { name, value } = e.target;
    setSearch(value);

    var result = [];
    members.some(mem => {
      if ((mem.firstName + ' ' + mem.lastName + ' ' + mem.memCountryID.countryName + ' ' + mem.memCountryID.stateName).toLowerCase().includes(value)) {
        result.push(mem);
      }
    });
    setFilteredData(result);
    // console.log('result is', result);
  };

  const handleClear = () => {
    setSearch("");
    setFilteredData(members);
  }

  // console.log('all members', allMember);
  // console.log('social membersss', socialMembers);

  const getState = () => {
    httpClient.get('/state').then(({ data }) => {
      if (data) {
        setState(data.data);
      } else {
      }
    });
  };

  const handleBack = () => {
    history.push('/social/listSystemGroups');
  };

  return (
    <PageContainer heading={socialID === '' ? 'Add Social Group' : 'Edit Social Group'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Social Group Name"
                variant="outlined"
                name="socialGroupName"
                value={socialDetails.socialGroupName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Social Group Description"
                variant="outlined"
                name="socialGroupDescription"
                value={socialDetails.socialGroupDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Created By</InputLabel>
                <Select
                  required
                  open={false}
                  name="createdByUserID"
                  label="Created By"
                  value={socialDetails.createdByUserID}
                  onChange={handleChange}>
                  <MenuItem value={'0'}>
                    <em>Super Admin</em>
                  </MenuItem>
                  {members &&
                    members.map(mem => (
                      <MenuItem key={mem && mem.memberID} value={mem && mem.memberID}>
                        {mem && mem.firstName + ' ' + mem.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Member to Associate</InputLabel>
                <Select
                  required
                  name="memberToAssociate"
                  label="Member to Associate"
                  value={socialDetails.memberToAssociate}
                  onChange={handleChange}>
                  <MenuItem value={0}>All States</MenuItem>
                  {state && state.map(st => <MenuItem value={st.stateID}>{st.stateName}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isSocialGroupPublic"
                    checked={socialDetails.isSocialGroupPublic}
                    onChange={handleCheck}
                    value={socialDetails.isSocialGroupPublic}
                    color="primary"
                  />
                }
                label="Make Social Group Public"
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="socialGroupActive"
                    checked={socialDetails.socialGroupActive}
                    onChange={handleCheck}
                    value={socialDetails.socialGroupActive}
                    color="primary"
                  />
                }
                label="Make Social Group Active"
              />
            </Grid>

            {socialDetails.isSocialGroupPublic && (
              <Grid item xs={12} style={{ borderTop:"1px solid #a8a8a8", marginTop:"40px" }}>
                <Box mt={3}>
                  <h2>Social Users</h2>
                </Box>
              </Grid>
            )}

            {socialDetails.isSocialGroupPublic && (
              <Grid item xs={12} md={8}>
                <Box mt={3}>
                  <InputLabel style={{ marginBottom: '10px' }}>Search</InputLabel>
                  <Box display={"flex"}>
                    <TextField name="search" value={search} onChange={handleSearchChange} variant="outlined" fullWidth InputProps={{ className: "text_field",  }} />
                    <Button onClick={handleClear} color='primary' variant='contained' style={{ marginLeft:"10px" }} >
                      <Clear fontSize='small' style={{ marginRight:"5px" }} /> Clear
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}

            {socialMembers.length ? (
              <Grid xs={12}>
                <Card style={{ padding:"20px", marginTop:"40px" }}>
                  <h3 style={{ marginBottom:"10px" }}>Selected Users</h3>
                  {members.map((mem,index) => {
                    if(socialMembers.length && socialMembers.includes(mem.memberID)){
                      return <span>{(mem.firstName + " " + mem.lastName + " (" + mem.memCountryID.stateName + ")")}  <Cancel fontSize='small' color='primary' onClick={() => handleRemoveMember(mem.memberID)} style={{ cursor:"pointer", marginRight:"10px", position:"relative", top:"5px", left:"-3px" }} /></span>
                    }
                  })}
                </Card>
              </Grid>
            ): ""}

            {socialDetails.isSocialGroupPublic && (
              <Grid item xs={12} style={{ columns: search ? '1' : '2 auto', margin:"20px" }}>
                {search.length === 0 &&
                <div className="member_checkbox">
                  <div>
                    <input
                      type="checkbox"
                      id={`check_select`}
                      name="allMember"
                      value={allMember}
                      onChange={e => handleCheckAll(e)}
                    />
                  </div>
                  <div>
                    <label id={`check_select`} for="checkbox">
                      Select All
                    </label>
                  </div>
                </div>
                }

                {filteredData &&
                  filteredData.map((mem, i) => (
                    <div className="member_checkbox">
                      <div>
                        <input
                          type="checkbox"
                          id={`check_${mem.memberID}`}
                          name="socialMembers"
                          value={socialMembers.length && socialMembers.includes(mem.memberID)}
                          checked={socialMembers.length && socialMembers.includes(mem.memberID)}
                          onChange={e => handleClickMember(e, mem.memberID)}
                        />
                      </div>
                      <div>
                        <label id={`check_${mem.memberID}`} for="checkbox">
                          {mem.firstName +
                            ' ' +
                            mem.lastName +
                            ' - (' +
                            mem.memCountryID.countryName +
                            ' - ' +
                            mem.memCountryID.stateName +
                            ' )'}
                        </label>
                      </div>
                    </div>
                  ))}
              </Grid>
            )}

            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  {socialID === '' ? 'Add Social Group' : 'Edit Social Group'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddSocialGroup;
